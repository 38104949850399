.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #757677;
  background: #fff;
  width: 100%;
  z-index: 5;
}

.subContainerCol {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.subContainerFlexible {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
}

.boldLabel {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #383838;
  margin: 0;
}

.brandText {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #757677;
  margin: 10px 0 0;
}

.explanatoryText {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #757677;
  margin: 0;
}

.labelText {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #383838;
  margin: 0;
}

.linkText {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #046B99;
  text-decoration: none;
  margin: 0;
}

.mt {
  margin-top: 30px;
}

.bbbLogo {
  height: 41px;
  width: 130px;
  margin-top: 0;
}

.mb {
  margin-bottom: 30px;
}

.mx {
  padding-left: 85px;
  padding-right: 85px;
}

@media only screen and (max-width: 600px) {
  .subContainerFlexible {
    flex-direction: column;
  }

  .bbbLogo {
    margin-top: 30px;
  }

  .mx {
    padding-left: 20px;
    padding-right: 20px;
  }
}
