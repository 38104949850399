.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0px;
  background: #fff;
  z-index: 5;
  box-shadow: 0px 4px 10px rgba(117, 118, 119, 0.25);
}

.subContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.digniFiLogo {
  height: 40px;
  width: 130px;
}

.labelText {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #383838;
}

.linkText {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #046B99;
  text-decoration: none;
}

.my {
  padding-top: 20px;
  padding-bottom: 20px;
}

.textMargin {
  margin-top: 0;
  margin-bottom: 2px;
}

.mr {
  margin-right: 13px;
}

.mx {
  padding-left: 85px;
  padding-right: 85px;
}

@media only screen and (max-width: 600px) {
  .subContainer {
    flex-direction: column;
    align-items: flex-end;
  }

  .mr {
    margin-right: 0;
  }

  .mx {
    padding-left: 20px;
    padding-right: 20px;
  }
}
