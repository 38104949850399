.container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex: 1;
}

.backdropBase {
  position: absolute;
  left: 0;
  right: 0;
  height: 50%;
}

.backdropTop {
  composes: backdropBase;
  top: 0;
  bottom: 50%;
  background-image: url('../../images/road-view.webp');
  background-size: cover;
  background-position: center;
}

.backdropBottom {
  composes: backdropBase;
  background: #F7F9FB;
  top: 50%;
}

.tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 30px;
  width: 500px;
  padding: 40px 60px;
  z-index: 1;
}

@media only screen and (max-width: 520px) {
  .tile {
    background: transparent;
  }

  .backdropTop {
    background: #F7F9FB;
  }
}
