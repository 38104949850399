@import './vars.css';

html, body, :global(#dignifi-ui) {
  height: 100%;
}

:global(#dignifi-ui) {
  display: flex;
  flex-direction: column;
}

:global(.centered) {
  text-align: center;
}

body {
  padding: 0;
  margin: 0;
  font-family: Montserrat, sans-serif;
}

label {
  display: block;
}

h2, p {
  margin: 0;
}

p {
  line-height: 26px;
}

h2 {
  font-size: 34px;
  color: var(--red);
  margin-bottom: 10px;
  text-align: center;
}

h3 {
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
}

a {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #046B99;
  text-decoration: none;
}
