.form {
  width: 100%;
}

.form > * {
  margin-top: 25px !important;
  display: block;
}

.error {
  margin-top: 25px;
  width: 100%;
  box-sizing: border-box;
}
